import { differenceInMilliseconds, format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import { capitalise } from '../../../utils/capitalise';
import { validateRequired } from '../../../utils/validateFields';
import { EXAM_FIELDS } from '../../../constants/examFields';

export const buildJoinUrl = (examSlot) => {
  if (isEmpty(examSlot)) {
    return;
  }
  const baseUrl = window.location.protocol + '//' + window.location.hostname + '/join/';
  return baseUrl + examSlot.id;
};

export const formatDateTime = (dateTime, format) => {
  if (!dateTime) {
    return undefined;
  }
  if (!format) {
    format = "yyyy-MM-dd'T'HH:mm:ss"; //format for render
  }
  const parsedDateTime = parseISO(dateTime);
  if (dateFnsIsValid(parsedDateTime)) {
    return dateFnsFormat(parsedDateTime, format);
  }
};

export const formatDisplayName = (string) => {
  if (string === undefined) {
    return string;
  }
  let formattedString = string ? capitalise(string) : string;
  return formattedString.replace("_", " ");
};

export const validateFields =  (slotDetails) => {
  if (isEmpty(slotDetails)) {
    throw new Error('Please provide the required details');
  }
  validateRequired(EXAM_FIELDS, slotDetails);
  // dateTime fields have been added into the validate required now, but as parseISO will now throw an error on an
  // empty parameter, we should also not let the unhelpful error through, but push it to the invalid date error
  const examStartTime = slotDetails.examStartDateTime && parseISO(slotDetails.examStartDateTime);
  const examEndTime = slotDetails.examEndDateTime && parseISO(slotDetails.examEndDateTime);
  if (!dateFnsIsValid(examStartTime)) {
    throw new Error('The given exam start date/time is invalid');
  }
  if (!dateFnsIsValid(examEndTime)) {
    throw new Error('The given exam end date/time is invalid');
  }

  if (differenceInMilliseconds(examEndTime, examStartTime) <= 0) {
    // exam end time cannot be equal to or less than the start time
    throw new Error('The given exam end date/time is before the start date/time');
  }

  return true;
};
