import React, { useCallback, useContext, useEffect, useState } from 'react';
import { format as dateFnsFormat } from 'date-fns';
import { isEmpty, sortBy } from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { authContext } from '../authContext';
import { monitoringContext } from './context/MonitoringContext';
import JoinShift from './form/JoinShift';
import Notice from './notification/Notice';
import ShiftService from '../services/ShiftService';
import { MSG_404 } from '../constants/login';
import { ACTIONS } from '../constants/monitorSessions';
import SHIFT_STATUSES from '../constants/shift';

const styles = {
  heading: {
    marginRight: 2,
  },
  responsiveImage: {
    maxWidth: '70%',
    marginBottom: 4,
  },
  listRoot: {
    flexGrow: 1,
    maxHeight: '75vh',
    overflowY: 'auto',
    '& > div': {
      width: '100%',
    },
    '&::-webkit-scrollbar': {
      width: 1,
      backgroundColor: 'transparent',

    },
    '&:hover::-webkit-scrollbar': {
      backgroundColor: '#eee',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: 1,
    },
  },
};

function MonitoringShifts() {
  const { user } = useContext(authContext);
  const { dispatch } = useContext(monitoringContext);
  const [shifts, setShifts] = useState();
  const [shiftUpdated, setShiftUpdated] = useState(true);
  const [requestError, setRequestError] = useState();
  const [multiShiftError, setMultiShiftError] = useState(false)

  const getShifts = useCallback(async (controller) => {
    try {
      const shiftResponse = await ShiftService.getSupervisorShifts(user.id, new Date(), undefined, false, controller.signal);
      const activeShift = shiftResponse
        .filter(shift => shift.status === SHIFT_STATUSES.ACTIVE.mapper || shift.status === SHIFT_STATUSES.INACTIVE.mapper);
      if(activeShift.length === 1) {
        dispatch({type: ACTIONS.SET_ACTIVE_SHIFT, value: activeShift[0]});
        setMultiShiftError(false);
        setRequestError(undefined);
      } else {
        if(activeShift.length > 1){
          setRequestError('You currently have multiple active sessions, please end the incorrect sessions.');
          setMultiShiftError(true);
        }
        dispatch({type: ACTIONS.SET_ACTIVE_SHIFT, value: undefined});
      }
      setShifts(sortBy(shiftResponse, ['startTime']));
    } catch(error) {
      if (error.message === MSG_404) {
        setShifts([])
      } else {
        console.log(error);
        setRequestError('Sorry, there was an error retrieving your sessions. Please refresh the page to try again.');
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    shiftUpdated && getShifts(controller);
  }, [user, shiftUpdated, getShifts]);

  return (
    <Box display="flex" flexDirection="column" p={2} height="100vh" width="calc(70% - 1px)" boxSizing="border-box">
      <Box display="flex" alignItems="baseline">
        <Typography variant="h2" sx={styles.heading}>Today's sessions</Typography>
        <Typography variant="body1">{dateFnsFormat(new Date(), 'dd MMM yyyy')}</Typography>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box width="100%">
          {requestError &&
            <Notice noticeType="error">{requestError}</Notice>
          }
          {isEmpty(shifts) && !requestError
            ? <Notice noticeType="notice">You aren't allocated to any sessions today</Notice>
            : !isEmpty(shifts) &&
                <List sx={styles.listRoot}>
                  {shifts.map((shift) => {
                    return (
                      <JoinShift
                        key={shift.id}
                        shift={shift}
                        setShiftUpdated={setShiftUpdated}
                        multiShiftError={multiShiftError}
                      />
                    )
                  })}
                </List>
          }
        </Box>
        {!isEmpty(shifts) &&
          <Box maxWidth="30%" ml={4} pt={1}>
            <Box component="img" sx={styles.responsiveImage} alt='Session button selection example' src="/images/shift-example.png" />
            <Typography variant="h4" component="h3">Click &lsquo;start supervising&rsquo; to start your session.</Typography>
            <Typography>
              Remember to click &lsquo;end supervising&rsquo; when you have finished.
            </Typography>
          </Box>
        }
      </Box>
    </Box>
  );
}

export default MonitoringShifts;
