import { differenceInSeconds, isValid as dateFnsIsValid, parseISO } from 'date-fns';

const getMeetingVideoTime = (actualTime, meetings) => {
  let result = null;

  meetings.forEach((m, idx) => {
    // Get time first person joined the meeting
    let firstJoinTime = null;
    m.participants.forEach(p => {
      const joinTime = p.joinedTime && parseISO(p.joinedTime);
      if (!dateFnsIsValid(joinTime)) {
        return;
      }
      if (firstJoinTime === null || joinTime < firstJoinTime) {
        firstJoinTime = joinTime;
      }
    });
    if (firstJoinTime === null) {
      // Couldn't find a start time, skip
      return;
    }
    const secondsSinceMeetingStart = differenceInSeconds(actualTime, firstJoinTime);
    if (secondsSinceMeetingStart > 0 && (result === null || result.secondsSinceStart > secondsSinceMeetingStart)) {
      result = {
        meeting: idx,
        secondsSinceStart: secondsSinceMeetingStart,
      };
    }
  });

  return result;
}

export default getMeetingVideoTime;