import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import { onRoleAssignmentChangeHandler } from './handler/onRoleAssignmentChangeHandler';
import SupervisorHandlerService from '../../services/SupervisorHandlerService';
import ExamSessionService from '../../services/ExamSessionService';
import AppOptionService from '../../services/AppOptionService';
import { monitoringContext } from '../context/MonitoringContext';
import { authContext } from '../../authContext';
import { ACTIONS, MONITOR_LIST_TYPES } from '../../constants/monitorSessions';
import { MESSAGE_TYPES } from '../../constants/supervisorHandler';
import { MSG_404 } from '../../constants/login';
import { popupErrorMessages } from '../../constants/errors';
import HANDLER_SETTINGS from '../../config/supervisorHandlerSettings';
import examSessionIsVisibleToAssignedUser from '../../utils/examSessionIsVisibleToAssignedUser';

function AllocationHandler(props) {
  const { dispatch: monitorDispatch } = useContext(monitoringContext);
  const authState = useContext(authContext);
  const capabilityContextAccess = useRef(authState?.capabilityContextAccess);
  const { updateRequestState } = props;
  const [ supervisorHandlerConnection, setSupervisorHandlerConnection ] = useState(null);
  const [ handlerSocketUrl, setHandlerSocketUrl ] = useState(null);
  const [ snackbarMessage, setSnackbarMessage ] = useState(null);
  const [ snackbarType, setSnackbarType ] = useState("error");
  const [ snackbarOpen, setSnackbarOpen ] = useState(false);
  const fetchSessionsOnConnect = useRef(true);
  const refetchSessionsOnError = useRef(true);
  const controllerRef = useRef(new AbortController());

  const getCapabilityContextAccess = useCallback(() => capabilityContextAccess.current, [capabilityContextAccess]);

  const fetchUpdatedExamSessionList = useCallback(async () => {
    controllerRef.current = new AbortController();
    const controller = controllerRef.current;
    try {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      const examSessions = await ExamSessionService.getSupervisorMonitoringSessions(
        authState.user.id,
        today,
        tomorrow,
        controller.signal
      );
      updateRequestState(false, false, "");
      monitorDispatch({type: ACTIONS.SET_EXAM_SESSIONS, value: examSessions});
      console.debug(`[AllocationHandler]: Successfully fetched list of ${examSessions ? examSessions.length : 0} allocated exams`);
    } catch (error) {
      if (error.name !== 'AbortError') {
        if (error.message === MSG_404) {
          updateRequestState(false, false, "");
          monitorDispatch({type: ACTIONS.SET_EXAM_SESSIONS, value: []});
        } else {
          updateRequestState(false, true, error.message);
        }
      }
    }
  }, [authState.user.id, monitorDispatch, updateRequestState]);

  useEffect(() => {
    controllerRef.current = new AbortController();
    const controller = controllerRef.current;
    // Get the websocket url to use
    const fetchWebsocketUrl = async () => {
      try {
        const optionData = await AppOptionService.getOption(HANDLER_SETTINGS.websocketOptionName, controller.signal);
        setHandlerSocketUrl(optionData.value);
        console.debug('[AllocationHandler]: Fetched allocation change websocket option successfully', optionData.value);
      } catch (error) {
        console.error('[AllocationHandler]: Unable to fetch supervisor handler websocket url from Core API service', error);
        if (error.name !== 'AbortError') {
          updateRequestState(false, true, "Unable to fetch supervisor handler websocket url from Core API service");
        }
      }
    };
    fetchWebsocketUrl();
    return (() => { controller.abort() });
  }, [updateRequestState]);

  useEffect(() => {
    // On mounting this component, set up service
    let newHandlerConnection;
    // Create a connection function
    const connectToHandler = () => {
      newHandlerConnection.connect(handlerSocketUrl, authState.getAccessToken)
        .then(() => {
          console.debug('[AllocationHandler]: Successfully established connection to allocation handler websocket');
        })
        .catch((err) => {
          console.error('[AllocationHandler]: Failed to reconnect to allocation handler', err,
            `Trying again in ${HANDLER_SETTINGS.retryConnectionAfterMs}ms`);
          setSnackbarMessage(popupErrorMessages.allocationHandlerDisconnection);
          setSnackbarType("error");
          setSnackbarOpen(true);
          setTimeout(() => connectToHandler(), HANDLER_SETTINGS.retryConnectionAfterMs);
          // if no connection to handler, load initial assigned students (reconnection attempts to handler will occur and reload as needed)
          // only fetch if we haven't already fetched on error
          refetchSessionsOnError.current && fetchUpdatedExamSessionList();
          refetchSessionsOnError.current = false;
        });
    }

    const setupHandlerConnection = () => {
      newHandlerConnection = new SupervisorHandlerService();
      // Set up callback function for assignment change to add/remove new exam slot
      newHandlerConnection.registerHandler('onAssignmentChange', (messageType, examDetails) => {
        let examIsNowVisible = examSessionIsVisibleToAssignedUser(examDetails, authState.user.id, authState.features, MONITOR_LIST_TYPES);
        switch (messageType) {
          case MESSAGE_TYPES.ASSIGNED:
            // A new exam slot has been assigned to the supervisor/onboarder, add this to the list
            if (examIsNowVisible) {
              console.debug(`[AllocationHandler]: Assigned to exam slot ${examDetails.id}, updating list`);
              monitorDispatch({type: ACTIONS.ADD_EXAM_SESSION, value: examDetails});
            }
            break;
          case MESSAGE_TYPES.UNASSIGNED:
            if (examIsNowVisible) {
              // User has been unassigned from some role, but we remain assigned in the appropriate monitoring role for the exam
              // session at the moment, so only update the exam details.
              console.debug(`[AllocationHandler]: Exam slot ${examDetails.id} assignments changed for other phases, updating.`);
              monitorDispatch({type: ACTIONS.ADD_EXAM_SESSION, value: examDetails});
            } else {
              // User has been unassigned from exam session monitoring, so it can be safely removed from the list
              console.debug(`[AllocationHandler]: Exam slot ${examDetails.id} assigned to different user, removing from list.`);
              monitorDispatch({type: ACTIONS.REMOVE_EXAM_SESSION, value: {id: examDetails.id}});
            }
            break;
          case MESSAGE_TYPES.DISMISSED:
            monitorDispatch({type: ACTIONS.REMOVE_EXAM_SESSION, value: {id: examDetails.id}});
            break;
          default:
            console.warn(`[AllocationHandler]: Unable to handle unknown assignment change event of type ${messageType}`);
            break;
        }
      });

      // Set up callback function for exam state change
      newHandlerConnection.registerHandler('onExamStateChange', (messageType, examDetails) => {
        let examIsNowVisible = examSessionIsVisibleToAssignedUser(examDetails, authState.user.id, authState.features, MONITOR_LIST_TYPES);
        switch (messageType) {
          case MESSAGE_TYPES.EXAM_UNLOCKED:
            // An exam has transitioned to a state that can be monitored, add to the list
            if (examIsNowVisible) {
              // If the exam gate has been opened and this user is the supervisor, then it stays on our dashboard
              console.debug(`[AllocationHandler]: Exam gate opened for assigned slot ${examDetails.id}, adding to supervision list`);
              monitorDispatch({type: ACTIONS.ADD_EXAM_SESSION, value: examDetails});
            } else {
              // If the exam gate has been opened and we aren't a supervisor, it can be removed from our tracked list
              console.debug(`[AllocationHandler]: Exam gate opened for assigned slot ${examDetails.id}, removing from onboarding list`);
              monitorDispatch({type: ACTIONS.REMOVE_EXAM_SESSION, value: {id: examDetails.id}});
            }
            break;
          case MESSAGE_TYPES.EXAM_LOCKED:
            // An exam has transitioned out of a state that can be monitored. For now, leave in the list
            // but update the state accordingly
            if (examIsNowVisible) {
              // If the exam gate has been locked and this user is the onboarder, we still need to keep the exam details
              console.debug(`[AllocationHandler]: Exam gate closed for assigned slot ${examDetails.id}, adding to onboarding list`);
              monitorDispatch({type: ACTIONS.ADD_EXAM_SESSION, value: examDetails});
            } else {
              // User is not the onboarder, so should be removed from our list
              console.debug(`[AllocationHandler]: Exam gate closed for assigned slot ${examDetails.id}, removing from supervision list`);
              monitorDispatch({type: ACTIONS.REMOVE_EXAM_SESSION, value: {id: examDetails.id}});
            }
            break;
          case MESSAGE_TYPES.SUBMITTED:
            // An exam has transitioned into submitted state. Update the exam list accordingly (we don't check for visibility
            // here because the submitted notification doesn't have a full set of exam details included)
            console.debug(`[AllocationHandler]: Student submitted for assigned slot ${examDetails.id}, updating status`);
            monitorDispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: {id: examDetails.id, examState: examDetails.examState}});
            break;
          default:
            console.warn(`[AllocationHandler]: Unable to handle unknown exam state change event of type ${messageType}`);
            break;
        }
      });

      // Setup callback function for role assignment updates
      newHandlerConnection.registerHandler('onRoleAssignmentChange', (messageType, roleAssignment) => (
        onRoleAssignmentChangeHandler(messageType, roleAssignment, getCapabilityContextAccess, authState.setCapabilityContextAccess)
      ));

      // Set up callback function for successful connection
      newHandlerConnection.registerHandler('onConnection', () => {
        // After socket has connected successfully, we need to fetch an updated exam list (to account for any changes while
        // the connection was being negotiated).
        console.debug('[AllocationHandler]: Connected successfully, attempting exam list fetch');
        setSnackbarOpen(false);
        // fetch on new connections, only if we haven't previously successfully connected
        fetchSessionsOnConnect.current && fetchUpdatedExamSessionList();
        fetchSessionsOnConnect.current = false;
      });

      // Set up callback function for disconnection
      newHandlerConnection.registerHandler('onDisconnection', (clientInitiated, tryReconnect) => {
        // If the socket is closed, then try to reconnect if necessary, using whatever access token is currently defined in the
        // auth context.
        console.debug(`[AllocationHandler]: Allocation websocket ${clientInitiated ? 'client initiated' : 'unexpected'}`
          + ` disconnection, ${tryReconnect ? '' : 'not '}attempting reconnect`);
        if (!clientInitiated) {
          // This was server initiated, so stop rendering the page elements until we can reconnect
          setSnackbarMessage(popupErrorMessages.allocationHandlerDisconnection);
          setSnackbarType("error");
          setSnackbarOpen(true);
        }
        if (tryReconnect) {
          connectToHandler();
        }
        refetchSessionsOnError.current = true;
      });
    }

    const initialiseAllocationHandler = () => {
      // If we already have a supervisor handler connection, do nothing
      if (supervisorHandlerConnection) {
        return;
      }

      // If there is no websocket URL available, do nothing
      if (!handlerSocketUrl) {
        return;
      }

      setupHandlerConnection();
      setSupervisorHandlerConnection(newHandlerConnection);
      connectToHandler();
    };

    // Store the handler into local state, and initialise connection
    initialiseAllocationHandler();

  }, [
    updateRequestState,
    supervisorHandlerConnection,
    monitorDispatch,
    fetchUpdatedExamSessionList,
    getCapabilityContextAccess,
    authState.getAccessToken,
    handlerSocketUrl,
    authState.user.id,
    authState.features,
    authState.setCapabilityContextAccess,
  ]);

  const handleSnackbarClose = (_e, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => { capabilityContextAccess.current = authState.capabilityContextAccess }, [authState.capabilityContextAccess]);

  return (<>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={snackbarType !== "success" ? null : 4000}
      onClose={handleSnackbarClose}
      anchorOrigin={{vertical: 'top', horizontal: 'left'}}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarType}
        sx={{ fontSize: '1rem' }}
      >{snackbarMessage}</Alert>
    </Snackbar>
    {props.children}
  </>);
}

AllocationHandler.propTypes = {
  updateRequestState: PropTypes.func.isRequired,
};

export default AllocationHandler;
