import React from 'react';
import PropTypes from 'prop-types';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

class ShiftUploadForm extends React.Component {
  state = {
    uploadDate: '',
  };

  componentDidMount() {
    this.setDate(this.formatDate(new Date()));
  }

  setDate = (date) => {
    this.setState({uploadDate: date},
    () => this.props.setParams({shiftDate: this.state.uploadDate}));
  }

  formatDate = (date) => {
    const format = "yyyy-MM-dd";
    if(dateFnsIsValid(date)) {
      return dateFnsFormat(date, format);
    }
  };

  render() {
    const { uploadDate } = this.state;

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TextField
          sx={{ my: 1, mx: 0 }}
          id="upload-shift-date"
          label="Date of sessions"
          value={uploadDate}
          type='date'
          onChange={(event) => this.setDate(event.target.value)}
          required
          InputLabelProps={{
            shrink: true,
          }}
          error={!uploadDate || !dateFnsIsValid(parseISO(uploadDate))}
          size='medium'
          variant='outlined'
          fullWidth
        />
      </LocalizationProvider>
    );
  }
}

ShiftUploadForm.propTypes = {
  setParams: PropTypes.func.isRequired,
};

export default ShiftUploadForm;
