import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    scrollMarginTop: '80px', // account for fixed header and heading margin
    minHeight: '100%',
  },
  childWrapper: {
    flexGrow: '1',
    maxWidth: 'calc(100% - 25px)',
  },
  horizontalScroll: {
    overflowX: 'auto',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 1,
  },
  toTopButton: {
    position: 'sticky',
    bottom: '10px',
    backgroundColor: 'white',
  },
  toBottomButton: {
    position: 'sticky',
    top: '80px',
    backgroundColor: 'white',
  },
};

function ScrollButtons(props) {

  const { children, horizontalScroll } = props;
  const pageContainer = useRef();

  const scrollToPosition = (alignPosition) => {
    pageContainer.current.scrollIntoView({ behavior: 'smooth', block: alignPosition, inline: 'nearest' });
  };

  const displayScrollButton = (alignPosition) => {
    const label = `Scroll to ${alignPosition}`;
    return (
      <Tooltip title={label}>
        <IconButton
          onClick={() => scrollToPosition(alignPosition === 'top' ? 'start' : 'end')}
          aria-label={label}
          variant="contained"
          sx={alignPosition === 'top' ? styles.toTopButton : styles.toBottomButton}
          size="small"
        >
          { alignPosition === 'top' ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> }
        </IconButton>
      </Tooltip>

    )
  }
  return (
    <Box ref={pageContainer} sx={styles.container}>
      <Box sx={horizontalScroll ? { ...styles.childWrapper, ...styles.horizontalScroll } : styles.childWrapper}>
        { children }
      </Box>
      <Box sx={styles.buttonWrapper}>
        { displayScrollButton('bottom') }
        { displayScrollButton('top') }
      </Box>
    </Box>
  )
}

ScrollButtons.propTypes = {
  children: PropTypes.node,
  horizontalScroll: PropTypes.bool,
}

export default ScrollButtons;
