import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { compact, isEmpty, sortBy } from 'lodash';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';

import { AuthConsumer } from '../authContext';
import Can from '../components/Can';
import ExamFlagAndRecordingContainer from '../components/container/ExamFlagAndRecordingContainer';
import FlagService from '../services/FlagService';
import Notice from '../components/notification/Notice';
import Routing from '../utils/Routing';
import ScrollButtons from '../components/form/ScrollButtons';
import SearchFormByOptions from '../components/search/SearchFormByOptions';
import {
  EXAM_RECORDINGS_SEARCH_OPTIONS,
  GROUP_IDS,
  RECORDINGS_FLAG_SEARCH_FIELD_TEMPLATE,
  RECORDINGS_SEARCH_FIELDS } from '../constants/examRecordings';
import FLAGS from '../constants/flags';

function ListRecording(_props) {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const [flags, setFlags] = useState();
  const [hasRequestErrored, setHasRequestErrored] = useState(false);

  const queryParams =  qs.parse(location?.search, { ignoreQueryPrefix: true, depth: 1 });

  const selectedDate = queryParams?.date || null;
  const selectedSearchTerm = queryParams?.context || '';
  const selectedStudent = queryParams?.student ? [{label: queryParams?.studentName, value: queryParams?.student}] : null;
  const flagTypes = compact([].concat(queryParams?.flagType));
  const groupBy = queryParams?.groupBy || GROUP_IDS.DATE_UNIT;

  const canNavigate = (params) => {
    if(!params) { return false; }
    const { date, searchText, userLookup } = params;
    return Boolean(date || searchText || userLookup);
  }

  const getRoute = (params) => {
    const { date, searchText, userLookup, searchMultiCombo, searchSingleSelect } = params;
    let urlParams = new URLSearchParams();
    if (date) {
      let parsedDate = dateFnsIsValid(date) ? date : parseISO(date);
      urlParams.append('date', dateFnsFormat(parsedDate, "yyyy-MM-dd"));
    }
    if (!isEmpty(searchText)) {
      urlParams.append('context', searchText);
    }
    if (!isEmpty(userLookup)) {
      //TODO if we allow multi lookup, fix here to not just get first array element
      urlParams.append('student', userLookup[0].value);
      urlParams.append('studentName', userLookup[0].label);
    }
    if (!isEmpty(searchMultiCombo)) {
      searchMultiCombo.forEach(item => {
        urlParams.append('flagType', item.id);
      })
    }
    urlParams.append('groupBy', searchSingleSelect);
    return `${Routing.MANAGE_RECORDING}/searchBy?${urlParams}`;
  };


  useEffect(() => {
    const controller = new AbortController();
    const getFlags = async () => {
      try {
        const flags = await FlagService.getAllFlags(controller.signal);
        setFlags(flags);
        setHasRequestErrored(false);
        setErrorMessage('');
      } catch (error) {
        if (!controller.signal?.aborted) {
          setHasRequestErrored(true);
          setErrorMessage('Error: unable to get flags');
        }
      }
    };

    if (!flags && !hasRequestErrored) {
      getFlags();
    }

    return () => { controller.abort() }
  }, [flags, hasRequestErrored, setErrorMessage, setFlags, setHasRequestErrored]);

  const formatFlagsForSearch = () => {
    return !isEmpty(flags) && sortBy(compact(flags.map(flag => {
      if (!FLAGS[flag.eventType] || FLAGS[flag.eventType]?.hideFromSearch === true) {
        return undefined;
      }
      return { id: flag.eventType, label: flag.name };
    })), 'label');
  };

  const examSessionPage = (
    <ScrollButtons>
      <Typography variant="h2">Recordings</Typography>
      {hasRequestErrored &&
        <Notice noticeType="error">{errorMessage}</Notice>
      }
      <Box flexGrow="1" mb={2}>
        <SearchFormByOptions
          key={location.key}
          canNavigate={canNavigate}
          fieldsToShow={EXAM_RECORDINGS_SEARCH_OPTIONS}
          getRoute={getRoute}
          labels={{ fieldsetLabel: 'Search for exam recordings' }}
          searchFields={[...RECORDINGS_SEARCH_FIELDS, { ...RECORDINGS_FLAG_SEARCH_FIELD_TEMPLATE, options: formatFlagsForSearch() } ]}
          uriParams={{
            date: selectedDate,
            searchText: selectedSearchTerm,
            userLookup: selectedStudent,
            searchMultiCombo: flagTypes,
            searchSingleSelect: groupBy,
          }}
        />
      </Box>
      {!hasRequestErrored && !isEmpty(queryParams)  && flags &&
      <ExamFlagAndRecordingContainer
        key={location.key}
        flags={flags}
        groupBy={groupBy}
        selectedDate={selectedDate}
        selectedSearchTerm={selectedSearchTerm}
        selectedStudent={selectedStudent?.[0]?.value}
        selectedFlagTypes={flagTypes}
      />
      }
    </ScrollButtons>
  )

  return (
    <AuthConsumer>
      {({capabilityContextAccess}) => (
        <Can
          capabilityContextAccess={capabilityContextAccess}
          contextRequired={false}
          perform={['REVIEW_EXAM', 'MANAGE_INTEGRITY', 'VIEW_INTEGRITY']}
          yes={() => (examSessionPage)}
        />
      )}
    </AuthConsumer>
  );
}

export default ListRecording;
