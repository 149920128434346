import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { monitoringContext } from '../context/MonitoringContext';
import { MONITOR_LIST_TYPES } from '../../constants/monitorSessions';
import { authContext } from '../../authContext';
import MeetingConnect from '../audioVisual/MeetingConnect';
import examSessionIsVisibleToAssignedUser from '../../utils/examSessionIsVisibleToAssignedUser';

function MonitoringMeetingConnectManager(props) {
  const { features } = props;

  const { state: monitoringState } = useContext(monitoringContext);
  const { user } = useContext(authContext);
  const [monitoredSessions, setMonitoredSessions] = useState([]);

  console.debug('6787: MonitoringMeetingConnectManager rerender: props, context, localState', props, monitoringState, { monitoredSessions });

  useEffect(() => {
    // Obtain a list of all session ids that will be displayed on the user's dashboard, so
    // meeting connection components can be instantiated.
    const visibleSessions = Object.values(monitoringState.examSessions)
      .filter(s => examSessionIsVisibleToAssignedUser(s, user.id, features, MONITOR_LIST_TYPES))
      .map(s => s.id)
      .sort();

    // Update the state accordingly with the derived list
    setMonitoredSessions(visibleSessions);
  }, [monitoringState.examSessions, user.id, features])

  return (
    <>
      {monitoredSessions.map(slotId => <MeetingConnect key={slotId} slotId={slotId} />)}
    </>
  );
}

MonitoringMeetingConnectManager.propTypes = {
  features: PropTypes.object.isRequired,
};

export default MonitoringMeetingConnectManager;
