import { isEmpty, min } from 'lodash';
import { isValid as dateIsValid, parseISO } from 'date-fns';

const getFirstTimeFromNotices = (notices) => {
  if(isEmpty(notices)) {
    return undefined;
  }
  const noticeTimes = notices.map((notice) => {
    if(dateIsValid(notice.timestamp)) {
      return notice.timestamp;
    }
    let noticeTime = notice.creationDateTime && parseISO(notice.creationDateTime).getTime();
    if(dateIsValid(noticeTime)) {
      return noticeTime;
    }
    return undefined;
  });
  return min(noticeTimes);
}

export default getFirstTimeFromNotices;
