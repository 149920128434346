import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { filter, first, groupBy, isEmpty, map, sortBy, toUpper } from 'lodash';
import { isValid as dateFnsIsValid, parseISO } from 'date-fns';

import ExamSessionSummaryActionMenu from '../form/ExamSessionSummaryActionMenu';
import ExamDetailsPopup from '../popup/ExamDetailsPopup';
import { authContext } from '../../authContext';
import { CanThey } from '../Can';
import getSessionTime from '../../utils/getSessionTime';
import { getFormattedDate } from '../../utils/getExamDetails';
import isAgentExam from "../../utils/isAgentExam";
import { buttonLinkStyle } from '../../config/theme';
import { EXAM_SESSION_CAPABILITIES as CAPABILITIES } from '../../constants/examSessions';


class ExamSessionSummaryTable extends React.Component {

  summarize = (examSessionList) => {
    if (isEmpty(examSessionList)) {
      return undefined;
    }

    const groupedList = groupBy(examSessionList, 'context.id');

    const summaryList = Object.keys(groupedList).map((examGroup) => {
      const numberOfExams = groupedList[examGroup].length;
      const numberOfAgentExams = filter(groupedList[examGroup], (sess) => isAgentExam(sess)).length;
      const unAssignedSupervisor = filter(groupedList[examGroup], function (val) {
        if (isEmpty(val['supervisors'])) {
          return true;
        }
      }).length;

      const stdStart = first(map(groupedList[examGroup], 'examStartStandard'));
      const stdEnd = first(map(groupedList[examGroup], 'examEndStandard'));
      const deletionDate = first(map(groupedList[examGroup], 'context.deletionDate'));
      const examStartTime = stdStart && parseISO(stdStart);
      const examEndTime = stdEnd && parseISO(stdEnd);
      const stdTime = (dateFnsIsValid(examStartTime) && dateFnsIsValid(examEndTime))
        ? getSessionTime(examStartTime, examEndTime)
        : '-';

      return {
        'id': first(map(groupedList[examGroup], 'context.id')),
        'name': first(map(groupedList[examGroup], 'context.name')),
        'stdTime': stdTime,
        'numberOfExams': numberOfExams,
        'supervisorsStatus': filter(groupedList[examGroup], 'supervisorJoined').length + "/" + numberOfExams,
        'studentsStatus': filter(groupedList[examGroup], 'studentJoined').length + "/" + numberOfExams,
        'agentsStatus': filter(groupedList[examGroup], 'agentJoined').length + "/" + numberOfAgentExams,
        'unAssigned': unAssignedSupervisor,
        'examStartTime': examStartTime,
        'stdStart': stdStart,
        'stdEnd': stdEnd,
        'humanSupervised': first(map(groupedList[examGroup], 'humanSupervised')),
        'aiType': first(map(groupedList[examGroup], 'aiType')),
        'onboardingType': first(map(groupedList[examGroup], 'onboardingType')),
        'deletionDate': deletionDate,
      };
    });

    return sortBy(summaryList, 'examStartTime', (item) => { return toUpper(item.name) })
  };

  extractSummaries = (examSummaryList) => {
    if (isEmpty(examSummaryList)) {
      return undefined;
    }

    const extractedSummaryList = examSummaryList.map((examGroup) => {
      const numberOfExams = examGroup.total;
      const unAssignedSupervisor = examGroup.total - examGroup.users.SUPERVISOR.assignedCount;
      const examStartTime = examGroup.examStartStandard && parseISO(examGroup.examStartStandard);
      const examEndTime = examGroup.examEndStandard && parseISO(examGroup.examEndStandard);
      const stdTime = (dateFnsIsValid(examStartTime) && dateFnsIsValid(examEndTime))
        ? getSessionTime(examStartTime, examEndTime)
        : '-';

      return {
        'id': examGroup.context.id,
        'name': examGroup.context.name,
        'stdTime': stdTime,
        'numberOfExams': examGroup.total,
        'supervisorsStatus': examGroup.users.SUPERVISOR.joinedCount + "/" + numberOfExams,
        'studentsStatus': examGroup.users.STUDENT.joinedCount + "/" + numberOfExams,
        'agentsStatus': examGroup.users.AGENT.joinedCount,
        'unAssigned': unAssignedSupervisor,
        'examStartTime': examStartTime,
        'stdStart': examGroup.examStartStandard,
        'stdEnd': examGroup.examEndStandard,
        'deletionDate': examGroup.context.examDeletionDate,
      };
    });

    return sortBy(extractedSummaryList, 'examStartTime', (item) => { return toUpper(item.name) })
  };

  canPerformWithCapabilityInContext = (contextId, capability) => {
    if (isEmpty(contextId)) {
      return false;
    }
    return CanThey(this.context.capabilityContextAccess, true, capability, { id: contextId });
  }

  getFormattedDeletionDate = (deletionDate) => {
    return deletionDate ? getFormattedDate(deletionDate, 'dd/MMM/yyyy') : ' - ';
  }

  render() {
    const { examSummary, examSessionList } = this.props;

    // if a summary is present use it, otherwise construct one from the exam session list
    const examSummaryList = examSummary ? this.extractSummaries(examSummary) : this.summarize(examSessionList);

    const canAddSlot = CanThey(this.context.capabilityContextAccess, false, CAPABILITIES.manageExam);
    const canModifyDeletionDate = CanThey(this.context.capabilityContextAccess, false, CAPABILITIES.manageIntegrity);
    const canViewDeletionDate = CanThey(this.context.capabilityContextAccess, false, CAPABILITIES.viewIntegrity) || canModifyDeletionDate;
    const canPerformActions = canAddSlot || canModifyDeletionDate;

    if (examSummaryList === undefined) {
      return (<div>Summary unavailable</div>);
    } else {
      return (
        <>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Exam unit</TableCell>
                  <TableCell>Standard time</TableCell>
                  <TableCell>Exams per unit</TableCell>
                  <TableCell>Supervisors joined</TableCell>
                  <TableCell>Students joined</TableCell>
                  <TableCell>Supervisor unassigned</TableCell>
                  <TableCell>Agents joined</TableCell>
                  {canViewDeletionDate &&
                    <TableCell>Expiry Date</TableCell>
                  }
                  {canPerformActions && <TableCell></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {examSummaryList.map((exam) => {
                  return (
                    <TableRow key={exam.id}>
                      <TableCell sx={{ pr: 0 }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Button
                            aria-label={`Retrieve data for: ${exam.name}`}
                            key={`${exam.id}`}
                            onClick={(_e) => this.props.setHasFilteredExamSlotList(exam.id, this.props.selectedSession)}
                            sx={{ ...buttonLinkStyle, flexGrow: 1 }}
                          >
                            {exam.name}
                          </Button>
                          <ExamDetailsPopup
                            examContextName={exam.name}
                            examSession={{ context: { id: exam.id } }}
                            useFullButton={false}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>{exam.stdTime}</TableCell>
                      <TableCell>{exam.numberOfExams}</TableCell>
                      <TableCell>{exam.supervisorsStatus}</TableCell>
                      <TableCell>{exam.studentsStatus}</TableCell>
                      <TableCell>{exam.unAssigned}</TableCell>
                      <TableCell>{exam.agentsStatus}</TableCell>
                      {canViewDeletionDate &&
                        <TableCell>{(this.canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageIntegrity)
                          || this.canPerformWithCapabilityInContext(exam.id, CAPABILITIES.viewIntegrity)) &&
                            this.getFormattedDeletionDate(exam.deletionDate)
                          }
                        </TableCell>
                      }
                      {(canPerformActions) &&
                        <TableCell>
                          {(this.canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageExam)
                            || this.canPerformWithCapabilityInContext(exam.id, CAPABILITIES.manageIntegrity)) &&
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <ExamSessionSummaryActionMenu
                                exam={exam}
                                setHasSlotUpdated={this.props.setHasSlotUpdated}
                                refreshSlots={this.props.refreshSlots}
                              />
                            </Box>
                          }
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  }
}

ExamSessionSummaryTable.propTypes = {
  // either session list or summary should be provided
  examSessionList: PropTypes.array,
  examSummary: PropTypes.array,
  refreshSlots: PropTypes.func.isRequired,
  setHasSlotUpdated: PropTypes.func.isRequired,
  setHasFilteredExamSlotList: PropTypes.func.isRequired,
  selectedSession: PropTypes.string
};

export default ExamSessionSummaryTable;
ExamSessionSummaryTable.contextType = authContext;
