import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { has, isEmpty } from 'lodash';
import { format as dateFnsFormat, parseISO } from 'date-fns';
import ExamDetailsPopup from '../popup/ExamDetailsPopup';
import DownloadFileButton from '../form/DownloadFileButton';
import getSessionTime from '../../utils/getSessionTime';
import { getLastActiveSupervisor } from '../../utils/getExamSlotUser';
import {
  ROOM_SCAN_STATUS,
  ROOM_SCAN_STATUS_DESCRIPTION
} from '../../constants/roomScanStatuses';
import { EXAM_SESSION_ONBOARDING_TYPE } from '../../constants/examSessions';

const styles = {
  hidden: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
  downloadRoomScanButton: {
    marginLeft: 2,
  },
};

class ReviewHeader extends React.Component {

  getExamDateDisplay = (examDetails) => {
    const examSessionStart = examDetails.examStartDateTime && parseISO(examDetails.examStartDateTime);
    const examSessionEnd = examDetails.examEndDateTime && parseISO(examDetails.examEndDateTime);
    return `${dateFnsFormat(examSessionStart, 'dd MMM yyyy')} ${getSessionTime(examSessionStart, examSessionEnd)}`;
  }

  onRoomScanDownloadFail = (error) => {
    console.error('Room scan archive download failed', error);
  }

  getRoomScanStatus = (statusCode) => {
    const { examDetails } = this.props;
    if (examDetails.onboardingType === EXAM_SESSION_ONBOARDING_TYPE.SKIP
      && statusCode === ROOM_SCAN_STATUS.WAITING) {
      // If the onboarding type is skip, then show 'Pending' as 'N/A'
      return 'Not applicable';
    }

    if (!statusCode) {
      // If we haven't received a valid status, leave the status as unknown
      return 'Unavailable';
    }

    if (ROOM_SCAN_STATUS_DESCRIPTION.hasOwnProperty(statusCode)) {
      return ROOM_SCAN_STATUS_DESCRIPTION[statusCode];
    } else {
      return statusCode;
    }
  }

  render() {
    const { examDetails, studentDetails, roomScanDetails } = this.props;
    const borderStyle = '1px solid silver';
    const containerStyle = {
      borderBottom: { xs: borderStyle, md: 'none' },
      borderLeft: { xs: 'none', md: borderStyle },
      px: 2,
      py: { xs: 1, md: 0 }
    };
    const lastActiveSupervisor = getLastActiveSupervisor(examDetails);

    let roomScanDisplay;
    if (isEmpty(roomScanDetails)) {
      roomScanDisplay = <Typography>Unavailable</Typography>;
    } else {
      const uploadCount = has(roomScanDetails, 'images') ? roomScanDetails.images : 0;
      const downloadButtonConfig = {
        imageCount: uploadCount, // The number to show in the tooltip
        requiresUpdate: false,   // Just show in standard grey colour
      };
      roomScanDisplay = <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
        <Typography color={roomScanDetails.roomScanStatus === ROOM_SCAN_STATUS.ERROR ? "error" : "inherit"}>
          {this.getRoomScanStatus(roomScanDetails.roomScanStatus)}
        </Typography>
        {uploadCount > 0 &&
          <DownloadFileButton
            session={downloadButtonConfig}
            examSession={examDetails}
            fail={this.onRoomScanDownloadFail}
            size="small"
            sx={styles.downloadRoomScanButton}
          />
        }
      </Box>;
    }

    return (
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between" p={1} borderBottom={borderStyle}>
        <Typography variant="h2" sx={styles.hidden}>Exam session details</Typography>
        <Box px={2} sx={{ borderBottom: containerStyle.borderBottom, py: containerStyle.py}}>
          <Typography variant="caption" color="secondary" component="h3">Student profile</Typography>
          {studentDetails &&
            <>
              <Typography>{studentDetails.fullName}</Typography>
              {studentDetails.externalId && <Typography style={{ wordWrap: 'break-word' }}>ID: {studentDetails.externalId}</Typography>}
            </>
          }
        </Box>
        <Box sx={containerStyle}>
          <Typography variant="caption" color="secondary" component="h3">Exam slot</Typography>
          {has(examDetails, 'context') &&
            <>
              <Typography>{this.getExamDateDisplay(examDetails)}</Typography>
              <Typography>{examDetails.context.name}</Typography>
            </>
          }
        </Box>
        <Box sx={containerStyle}>
          <Typography variant="caption" color="secondary" component="h3">Exam information</Typography>
          <ExamDetailsPopup
            examContextName={examDetails?.context?.name}
            examSession={examDetails}
            searchBySlot={true}
            useFullButton={true}
          />
        </Box>
        <Box sx={containerStyle}>
          <Typography variant="caption" color="secondary" component="h3">Room Scan</Typography>
          {roomScanDisplay}
        </Box>
        <Box sx={containerStyle}>
          <Typography variant="caption" color="secondary" component="h3">Flags</Typography>
          <Typography variant="h2" component="p" color="primary">{isEmpty(examDetails.flags) ? 0 : examDetails.flags.length}</Typography>
        </Box>
        <Box sx={{ ...containerStyle, borderBottom: 'none' }}>
          <Typography variant="caption" color="secondary" component="h3">Supervisor</Typography>
          {lastActiveSupervisor !== undefined ?
            <>
              <Typography>{lastActiveSupervisor.fullName}</Typography>
              {lastActiveSupervisor.externalId && <Typography>ID: {lastActiveSupervisor.externalId}</Typography>}
            </>
            : <Typography>No supervisor assigned</Typography>
          }
        </Box>
      </Box>
    );
  }
}

ReviewHeader.propTypes = {
  examDetails: PropTypes.object.isRequired,
  studentDetails: PropTypes.object.isRequired,
  roomScanDetails: PropTypes.object,
};

export default ReviewHeader;
