import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DomainOutlined,
  PersonOutlineOutlined,
  SchoolOutlined,
} from '@mui/icons-material/';

import { parseISO } from 'date-fns';
import { has } from 'lodash';
import { authContext } from '../../authContext';
import QrCodePopup from '../qrCode/QrCodePopup';
import getSessionTime from '../../utils/getSessionTime';
import getExamSessionType from '../../utils/getExamSessionType';
import { EXAM_SESSION_ONBOARDING_TYPE } from '../../constants/examSessions';
import { FEATURE_TOGGLES } from '../../constants/featureToggles';

const noticesStyle = {
  '& > div': {
    lineHeight: 'initial',
  },
};

function ExamDetails(props) {

  const { examDetails, slotId, studentDetails, studentView } = props;
  const { features } = useContext(authContext);

  const examStartTime = examDetails.examStartDateTime && parseISO(examDetails.examStartDateTime);
  const examEndTime = examDetails.examEndDateTime && parseISO(examDetails.examEndDateTime);
  const displayQrPopup = !features?.[FEATURE_TOGGLES.AUTO_ONBOARD]
    && examDetails.onboardingType !== EXAM_SESSION_ONBOARDING_TYPE.SKIP;

  return(
    <>
      {examDetails && !studentView &&
        <Box
          color="primary.contrastText"
          bgcolor="secondary.dark"
          p={1}
          textAlign="center"
          flexGrow="1"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box flexGrow="1">
            <Typography component="h2" variant="body1">
              {examDetails.context.name} {getSessionTime(examStartTime, examEndTime)} ({studentDetails.fullName} {studentDetails.externalId})
            </Typography>
          </Box>
          <Box sx={noticesStyle} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
            {has(examDetails, 'humanSupervised') && has(examDetails, 'aiType') &&
              <Typography variant="caption" component="div">
                {getExamSessionType(examDetails.humanSupervised, examDetails.aiType)}
              </Typography>
            }
          </Box>
        </Box>
      }
      {examDetails && studentDetails && studentView &&
        <Box display="flex" flex="1">
          <Box
            alignItems="center"
            color="secondary.dark"
            display="flex"
            flex="1"
            flexWrap="wrap"
            justifyContent="flex-end"
          >
            <Typography component="h2" variant="body1">
              <Box display="flex" alignItems="center">
                <DomainOutlined sx={{ fontSize: 'inherit', mr: 0.5 }}/>
                {examDetails.context.name}
              </Box>
            </Typography>
            <Typography component="h3" variant="body1">
              <Box display="flex" alignItems="center">
                <PersonOutlineOutlined sx={{ fontSize: 'inherit', ml: 1, mr: 0.5 }}/>
                {studentDetails.fullName}
                <SchoolOutlined sx={{ fontSize: 'inherit', ml: 1, mr: 0.5 }}/>
                Student ID: {studentDetails.externalId}
              </Box>
            </Typography>
          </Box>
          { displayQrPopup &&
            <QrCodePopup slotId={slotId} />
          }
        </Box>
      }
    </>
  )
}

ExamDetails.propTypes = {
  slotId: PropTypes.string.isRequired,
  examDetails: PropTypes.object.isRequired,
  studentDetails: PropTypes.object.isRequired,
  studentView: PropTypes.bool,
}

export default ExamDetails;
