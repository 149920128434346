import { isEmpty, sortBy, uniqBy } from 'lodash';
import { isValid, parseISO } from 'date-fns';

import { MSG_404 } from '../../constants/login';
import { STUDENT, SUPERVISOR } from '../../constants/chat';
import { USER_TYPES } from '../../constants/users';
import ExamSessionService from '../../services/ExamSessionService';
import MeetingService from '../../services/MeetingService';

export const retrieveChatMessages = async (examDetails, abortControllerSignal) => {
  return retrieveChatsFromCoreApi(examDetails.id, abortControllerSignal)
    .then(chatResponse => {
      if (isEmpty(chatResponse)) {
        return retrieveChatsFromRecording(examDetails, abortControllerSignal)
                 .then(chatsFromRecordings => chatsFromRecordings)
      } else {
        return chatResponse;
      }
    })
    .catch(error => {
      console.error("[retrieveChatMessages]: error thrown retrieving chats", error)
      return [];
    });
}

export const retrieveChatsFromCoreApi = async (slotId, abortControllerSignal) => {
  try {
    return await ExamSessionService.getExamSlotChatMessages(slotId, abortControllerSignal);
  } catch (error) {
    // don't throw a real error if the request was aborted (on navigation) or there was just no data found
    if (abortControllerSignal?.aborted || error.message === MSG_404) {
      return [];
    }
    throw error;
  }
}

const retrieveChatsFromRecording = async (examDetails, abortController) => {
  const chatMessages = [];
  for (let meeting of examDetails.meetings) {
    if (meeting.recordingsAvailable) {
      const meetingChatsResponse = await MeetingService.getRecordingChatMessages(meeting.id, abortController);
      chatMessages.push(...processChatRecordingXML(meeting, meetingChatsResponse));
    }
  }
  return chatMessages;
}

const processChatRecordingXML = (meetingDetails, chatMessagesXml) => {
  const popcornElement = chatMessagesXml.getElementsByTagName('popcorn')[0];
  const chatMessages = [...popcornElement.getElementsByTagName('chattimeline')];

  return chatMessages.map(chatMessage => ({
    sender: {
      legalName: chatMessage.getAttribute('name'),
      fullName: chatMessage.getAttribute('name'),
    },
    senderUserType: getUserType(meetingDetails, chatMessage),
    createdAt: calculateChatRecordingTime(meetingDetails, chatMessage).toISOString(),
    chatMessage: chatMessage.getAttribute('message'),
  }));
}

const getUserType = (meetingDetails, recordingChatMessage) => {
  const student = meetingDetails?.participants?.find(participant =>
    recordingChatMessage.getAttribute('name') === participant.user.fullName && participant.userType === USER_TYPES.STUDENT);
  return student ? USER_TYPES.STUDENT : USER_TYPES.SUPERVISOR;
}

const calculateChatRecordingTime = (meetingDetails, chatMessage) => {
  return new Date(new Date(meetingDetails.creationTime).getTime() + parseFloat(chatMessage.getAttribute('in'), 10)*1000);
};

// we could alter this for actually unread once we have a planned solution for read receipts
export const getUnreadChatMessagesCount = (chatMessages) => {
  return chatMessages?.filter(message => !message.fromSelf).length || 0;
};

const convertChatItemToMessage =(chatItem, userId, authorType) => {
  const showSupervisorDisplayName = authorType === STUDENT.authorType && chatItem.senderUserType === SUPERVISOR.authorType;
  const createdDate = parseISO(chatItem.createdAt);
  return {
    id: chatItem.id,
    text: chatItem.chatMessage,
    displayName: showSupervisorDisplayName ? SUPERVISOR.displayName : chatItem.sender?.fullName,
    timestamp: isValid(createdDate) && createdDate,
    userId: chatItem.sender?.id,
    fromSelf: userId === chatItem.sender?.id,
  };
};

export const sortAndDeDupeChats = (chats) => {
  return sortBy(uniqBy(chats, 'id'), 'timestamp');
};

export const convertChatItemsToChatHistory = (chatMessages, userId, authorType) => {
  return isEmpty(chatMessages)
    ? []
    : chatMessages.map(message => convertChatItemToMessage(message, userId, authorType));
};