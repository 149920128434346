import React from 'react';
import PropTypes from 'prop-types';
import { find, isEmpty } from 'lodash';
import Box from '@mui/material/Box';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

import { AuthConsumer } from '../../authContext';
import { CanThey } from '../Can';
import ContextOptionsForm from '../form/ContextOptionsForm';
import ContextService from '../../services/ContextService';
import Notice from '../notification/Notice';
import RequestStatusIndicator from '../notification/RequestStatusIndicator';
import Section from '../Section';
import flattenContexts from './helper/flattenContexts';
import { requestStatusIndicatorContainer } from '../../config/theme';

class ContextTree extends React.Component {

  state = {
    contextsTree: undefined,
    contextsList: undefined,
    rootContextId: undefined,
    selectedNode: undefined,
    isRequestPending: true,
    hasRequestErrored: false,
    errorMessage: '',
  };

  controller = new AbortController();

  componentDidMount() {
    this.getContexts();
  }

  getContexts = async () => {
    const { topId } = this.props;
    this.setState({
      isRequestPending: true,
      hasRequestErrored: false,
      errorMessage: '',
    });
    try {
      const contexts = await ContextService.getContexts({ topId }, this.controller.signal);
      const newContextList = flattenContexts(contexts)
      const rootContext = !this.state.rootContextId && (topId ? await this.getRootContextId() : contexts?.id)
      this.setState({
        contextsTree: contexts,
        contextsList: newContextList,
        rootContextId: rootContext || this.state.rootContextId,
        selectedNode: find(newContextList, context => context.id === this.state.selectedNode?.id),
        isRequestPending: false,
        hasRequestErrored: false,
      });
    } catch (error) {
      if (error.name !== 'AbortError') {
        this.setState({
          isRequestPending: false,
          hasRequestErrored: true,
          errorMessage: error.message,
        });
      }
    }
  };

  getRootContextId = async () => {
    try {
      const rootContext = await ContextService.getContexts({ withChildren: false }, this.controller.signal);
      return rootContext?.id;
    } catch (error) {
      // if this happens, the original request should have nothing and set a real error
      console.error('No root context found');
    }
  };

  getDisplayFormattedTree = (nodes) => {
    return [JSON.parse(JSON.stringify(nodes).replace(/"name"/g, '"label"'))];
  }

  setSelectedNode = (id, isSelected) => {
    const { contextsList } = this.state;
    this.setState({
      selectedNode: isSelected ? find(contextsList, context => context.id === id) : undefined,
    });
  };

  setHasContextUpdated = (hasUpdated) => {
    if (hasUpdated) {
      this.getContexts();
    }
  };

  render() {
    const {
      contextsList,
      contextsTree,
      errorMessage,
      hasRequestErrored,
      isRequestPending,
      rootContextId,
      selectedNode,
    } = this.state;

    return (
      <AuthConsumer>
        {({ capabilityContextAccess }) => (
          <>
            <Box sx={requestStatusIndicatorContainer}>
              <RequestStatusIndicator
                isPending={isRequestPending}
                isErrored={hasRequestErrored}
                errorMessage={errorMessage}
              />
            </Box>
            {/* This should never happen in real life.
               No matching contexts will throw a 404 error */}
            {isEmpty(contextsTree) && !isRequestPending && !hasRequestErrored &&
              <Section>
                <Notice noticeType="notice">There are no contexts in the system</Notice>
              </Section>
            }
            {!isEmpty(contextsTree) &&
              <Box display="flex">
                <Section sx={{ flexGrow: 1, mr: 4 }}>
                  <RichTreeView
                    defaultExpandedItems={[contextsTree.id]}
                    items={this.getDisplayFormattedTree(contextsTree)}
                    onItemSelectionToggle={(_e, id, isSelected) => this.setSelectedNode(id, isSelected)}
                  />
                </Section>
                <Section>
                  <ContextOptionsForm
                    canManageContext={CanThey(capabilityContextAccess, true, 'MANAGE_CONTEXT', selectedNode)}
                    canManageRoleAssignment={CanThey(capabilityContextAccess, true, 'MANAGE_ROLE_ASSIGNMENT', selectedNode)}
                    selectedNode={selectedNode}
                    contextsTree={contextsTree}
                    contextsList={contextsList}
                    rootContextId={rootContextId}
                    navigate={this.props.navigate}
                    setHasContextUpdated={this.setHasContextUpdated}
                  />
                </Section>
              </Box>
            }
            {hasRequestErrored &&
              <Notice noticeType="error">{errorMessage}</Notice>
            }
          </>
        )}
      </AuthConsumer>
    );
  }
}

ContextTree.propTypes = {
  navigate: PropTypes.func.isRequired,
  topId: PropTypes.string,
};

export default ContextTree;
