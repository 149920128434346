import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';

const styles = {
  tab: {
    minWidth: 'auto',
    position: 'relative',
    zIndex: 1,
    opacity: 1,
    color: 'secondary.main',
    marginBottom: '2px',
  },
  tabSelected: {
    color: 'primary.dark',
  },
  tabsIndicator: {
    width: '100%',
    zIndex: 0,
    backgroundColor: '#f5f6f6',
  },
  tabsHeading: {
    padding: 1.5,
  },
  tabsPanel: {
    color: 'secondary.main',
    paddingBottom: 4,
    borderLeft: '1px solid silver',
  },
  tabsList: {
    maxHeight: 'min(50vh, 500px)',
  },
}

function MeetingTabs(props) {
  const { meetings, viewMeeting, onChange } = props;

  if (meetings?.length <= 1) {
    return null;
  }

  const formatTimeString = (utcString) => {
    if (!utcString) {
      return "Unknown";
    }

    const inDate = parseISO(utcString);
    if (dateFnsIsValid(inDate)) {
      return dateFnsFormat(inDate, 'd MMM') + ' ' + dateFnsFormat(inDate, 'HH:mm')
    } else {
      return utcString;
    }
  }

  return (
    <Box sx={styles.tabsPanel} textAlign="center">
      <Typography variant="caption" color="secondary" component="h3" sx={styles.tabsHeading}>Videos</Typography>
      <Tabs
        sx={{ ...styles.tabsList, indicator: styles.tabsIndicator }}
        value={viewMeeting}
        onChange={onChange}
        orientation="vertical"
        variant="scrollable"
      >
        {meetings.map((m, idx) => {
          return (
            <Tab
              sx={{ ...styles.tab, selected: styles.tabSelected }}
              key={m.id}
              label={
                <Tooltip title={formatTimeString(m.creationTime)}>
                  <Typography variant="h1" component="div" color="inherit">{(idx + 1)}</Typography>
                </Tooltip>
              }
              value={idx}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

MeetingTabs.propTypes = {
  meetings: PropTypes.array,
  viewMeeting: PropTypes.number,
  onChange: PropTypes.func,
}

export default MeetingTabs