import { format as dateFnsFormat } from 'date-fns';
import { has, isEmpty, join, omit } from 'lodash';
import NetworkService from './NetworkService';
import MOCK from './mock';
import mockRequest from '../utils/mockRequest';
import { mapSessionFilters, mapUrlParams } from '../utils/mapParams';

export default class ExamSessionService {

  static async getExamSessionsForContextAndDate(contextId, selectedDate, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    const format = "yyyy-MM-dd'T00:00:00'X";
    const encodedStartDate = encodeURIComponent(dateFnsFormat(new Date(selectedDate), format));

    const response = await NetworkService.get(
     `/examslots/standard?examDate=${encodedStartDate}&contextId=${contextId}`
      , abortSignal);
    return await response.json();
  }

  static async getExamSessionsForSearchOptions(searchParams, searchFunction = 'manage', abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    if (has(searchParams, 'examStartDateAfter') && has(searchParams, 'examStartDateBefore')) {
      const format = "yyyy-MM-dd'T'HH:mm:ssX";
      const encodedStartDate = dateFnsFormat(new Date(searchParams.examStartDateAfter), format);
      const encodedEndDate = dateFnsFormat(new Date(searchParams.examStartDateBefore), format);

      searchParams.examStartDateAfter = encodedStartDate;
      searchParams.examStartDateBefore = encodedEndDate;
    }

    if (has(searchParams, 'examDate')) {
      const format = "yyyy-MM-dd'T00:00:00'X";
      searchParams.examDate = dateFnsFormat(new Date(searchParams.examDate), format);
    }

    searchParams['extraData'] = join([searchParams?.extraData, 'pool-assignments,active-onboard-step'], ',');

    const requestParams = mapUrlParams(omit(searchParams, 'filters'), mapSessionFilters(searchParams.filters))

    const response = await NetworkService.get(
      `/examslots/${searchFunction}?${requestParams}`, abortSignal);
    return await response.json();
  }

  static async getExamSessionsSummaryToManageForSearchOptions(searchParams, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONSSUMMARY);
    }

    searchParams['groupSummaryBy'] = 'context-id';

    if (has(searchParams, 'examStartDateAfter') && has(searchParams, 'examStartDateBefore')) {
      const format = "yyyy-MM-dd'T'HH:mm:ssX";
      const encodedStartDate = dateFnsFormat(new Date(searchParams.examStartDateAfter), format);
      const encodedEndDate = dateFnsFormat(new Date(searchParams.examStartDateBefore), format);

      searchParams.examStartDateAfter = encodedStartDate;
      searchParams.examStartDateBefore = encodedEndDate;
    }

    if (has(searchParams, 'examDate')) {
      searchParams.examDate = dateFnsFormat(new Date(searchParams.examDate), "yyyy-MM-dd'T00:00:00'X");
    }

    const requestParams = mapUrlParams(omit(searchParams, 'filters'), mapSessionFilters(searchParams.filters))

    const response = await NetworkService.get(`/examslots/manage?${requestParams}`, abortSignal);
    return await response.json();
  }

  static async getSupervisorSessions(selectedDate, selectedSupervisorId, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    const format = "yyyy-MM-dd'T00:00:00'X";

    let params = new URLSearchParams();
    params.append('examDate', dateFnsFormat(new Date(selectedDate), format));
    params.append('supervisorId', selectedSupervisorId);
    params.append('extraData', 'pool-assignments,active-onboard-step');

    const response = await NetworkService.get(
        `/examslots/manage?${params}`, abortSignal);
    return await response.json();
  }

  static async getExamSlotsForReview(context, date, studentId, flagTypes, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMFLAG_RECORDINGS);
    }

    let params = new URLSearchParams();

    if (!isEmpty(context)) {
      params.append('contextNameMatch', context);
    }

    if (date !== null) {
      params.append('examStartDateAfter', dateFnsFormat(new Date(date), "yyyy-MM-dd'T'00:00:00X"));
      params.append('examStartDateBefore', dateFnsFormat(new Date(date), "yyyy-MM-dd'T'23:59:59X"));
    }

    if (!isEmpty(studentId)) {
      params.append('studentId', studentId);
    }

    if (!isEmpty(flagTypes)) {
      flagTypes.forEach(type => {
        params.append('flagType', type);
      });
    }

    params.append('extraData', 'scheduled-delete-slots');

    const response = await NetworkService.get(
      `/examslots/review?`  + params
      , abortSignal);
    return await response.json();
  }

  static async getSupervisorUpcomingExamSessions(userId, examStartDate, examEndDate, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    const format = "yyyy-MM-dd'T'00:00:00X";

    let params = new URLSearchParams();
    params.append('supervisorId', userId);
    params.append('examStartDateAfter', dateFnsFormat(new Date(examStartDate), format));
    params.append('examStartDateBefore', dateFnsFormat(new Date(examEndDate), format));
    params.append('extraData', 'pool-assignments,active-onboard-step');

    const response = await NetworkService.get(
        `/examslots/standard?${params}`
        , abortSignal);
    return await response.json();
  }

  static async getOnboarderUpcomingExamSessions(userId, examStartDate, examEndDate, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    const format = "yyyy-MM-dd'T'00:00:00X";
    let params = new URLSearchParams();
    params.append('onboarderId', userId);
    params.append('examStartDateAfter', dateFnsFormat(new Date(examStartDate), format));
    params.append('examStartDateBefore', dateFnsFormat(new Date(examEndDate), format));
    params.append('extraData', 'pool-assignments,active-onboard-step');

    const response = await NetworkService.get(
        `/examslots/standard?${params}`
        , abortSignal);

    return await response.json();
  }


  static async getExamSession(id, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAM_SESSION);
    }
    const response = await NetworkService.get(`/examslot/${id}`, abortSignal);
    return await response.json();
  }

  static async getExamSessionSummary(id, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAM_SESSION);
    }
    const response = await NetworkService.get(`/examslot/${id}/summary`, abortSignal);
    return await response.json();
  }

  static async getSupervisorMonitoringSessions(userId, examStartDate, examEndDate, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAMSESSIONS);
    }

    const format = "yyyy-MM-dd'T'00:00:00X";
    const encodedStartDate = encodeURIComponent(dateFnsFormat(new Date(examStartDate), format));
    const encodedEndDate = encodeURIComponent(dateFnsFormat(new Date(examEndDate), format));

    const response = await NetworkService.get(
        `/examslots/monitoring?assignedUserId=${userId}&examStartDateAfter=${encodedStartDate}&examStartDateBefore=${encodedEndDate}`
        , abortSignal);
    return await response.json();
  }

  static async updateSupervisors(sessions, abortSignal) {
    return await NetworkService.put('/examslots/supervisors', sessions, abortSignal)
      .then(resp => {return resp});
  }

  static async updateExamSession(id, session, abortSignal) {
    return await NetworkService.patch(`/examslot/${id}`, session, abortSignal)
      .then(resp => {return resp});
  }

  static async updateExamSessionState(id, state, abortSignal) {
    return await NetworkService.patch(`/examslot/${id}/state`, state, abortSignal)
      .then(resp => {return resp});
  }

  static async confirmTermsConditions(id, state) {
    return await NetworkService.patch(`/examslot/${id}/privacy-confirmation`, state)
      .then(resp => {return resp});
  }

  static async joinMeeting(slotId, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.MEETING_DETAILS);
    }

    try {
      const getResponse = await NetworkService.get(
        `/examslot/${slotId}/meeting/participants`,
        abortSignal,
        'application/json',
        false
      );
      let jsonMeetingData;
      if (getResponse.status === 404) {
        // User not currently in meeting, request the user be added
        const postResponse = await NetworkService.post(
          `/examslot/${slotId}/meeting/participants`,
          {},
          'application/json',
          abortSignal
        );
        jsonMeetingData = await postResponse.json();
      } else if (getResponse.status === 200) {
        // Successfully retrieved meeting details
        jsonMeetingData = await getResponse.json();
      } else {
        // Handle error code.
        const errorMessage = await NetworkService.handleStatusCode(getResponse, false, 'application/json');
        throw new Error(errorMessage);
      }

      // Extract the hostname of the BBB servers from the url
      jsonMeetingData.remoteMediaServer = jsonMeetingData.host;
      jsonMeetingData.gatewayServer = jsonMeetingData.host;
      return jsonMeetingData;

    } catch (error) {
      if (abortSignal.aborted) {
        throw new Error({aborted: true, details: error});
      } else {
        console.error('Unable to retrieve meeting details', error);
        throw error;
      }

    }
  }

  static async endMeeting(slotId) {
    return await NetworkService.delete(`/examslot/${slotId}/meeting/participants`, {})
      .then(resp => {return resp});
  }

  static async completeSupervision(slotId) {
    return await NetworkService.patch(`/examslot/${slotId}/supervision-completed`, {})
      .then(resp => {return resp});
  }

  static async addExamSession(slotDetails, abortSignal) {
    const postResponse = await NetworkService.post(
      `/examslots`,
      [slotDetails],
      'application/json',
      abortSignal
    );

    const jsonData = await postResponse.json();

    // The end point expects a list of slots and returns the response list
    // We only care about the first element as we are always sending a single element
    return jsonData[0];
  }

  static async getExamSlotChatMessages(slotId, abortSignal) {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_MOCK_ENABLED === "true") {
      return mockRequest(MOCK.EXAM_SESSION_CHATS);
    }
    const response = await NetworkService.get(`/examslot/${slotId}/chat-messages`, abortSignal);

    return await response.json();
  }
}
