import { differenceInHours, differenceInMinutes, format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import { has, isEmpty, uniq } from 'lodash';

import { getLastActiveUserByType } from './getExamSlotUser';
import getSessionTime from './getSessionTime';
import isCannotExpireMisconductStatus from '../components/form/helper/isCannotExpireMisconductStatus';
import {
  EXAM_SESSION_ONBOARDING_TYPE,
  EXAM_SESSION_STATES,
  EXAM_SESSION_STATUS_LABEL,
  EXAM_SESSION_TYPE_LABELS as typeLabels
} from '../constants/examSessions';
import { USER_TYPES } from '../constants/users';

//TODO - extend these functions to be suitable for data requirements of exam session tablef

export const getFormattedDate = (date, format = 'dd MMM yyyy') => {
  if (!date) {
    return undefined;
  }
  const parsedDate = parseISO(date);
  if (dateFnsIsValid(parsedDate)) {
    return dateFnsFormat(parsedDate, format);
  }
  return undefined;
};

export const getExamTime = (startTime, endTime) => {
  const examStartTime = startTime && parseISO(startTime);
  const examEndTime = endTime && parseISO(endTime);

  if (dateFnsIsValid(examStartTime) && dateFnsIsValid(examEndTime)) {
    return getSessionTime(examStartTime, examEndTime);
  }
};

export const getExamDuration = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return undefined;
  }

  const examStartTime = parseISO(startTime);
  const examEndTime = parseISO(endTime);

  if (!examStartTime || !examEndTime) {
    return undefined;
  }
  if (!dateFnsIsValid(examStartTime) || !dateFnsIsValid(examEndTime)) {
    return undefined;
  }
  const hourDifference = differenceInHours(examEndTime, examStartTime);
  const minutesDifference = differenceInMinutes(examEndTime, examStartTime) - hourDifference * 60;
  let difference = hourDifference + 'h';
  if (minutesDifference !== 0) {
    difference = difference + minutesDifference + 'm';
  }
  return difference;
};

export const getExamState = (state) => {
  let examState;

  switch (state) {
    case EXAM_SESSION_STATES.canStart:
    case EXAM_SESSION_STATES.started:
      examState = EXAM_SESSION_STATUS_LABEL.GATE_OPENED;
      break;
    case EXAM_SESSION_STATES.submitted:
      examState = EXAM_SESSION_STATUS_LABEL.SUBMITTED;
      break;
    case EXAM_SESSION_STATES.pending:
    case undefined:
    default:
      examState = EXAM_SESSION_STATUS_LABEL.GATE_CLOSED;
      break;
  }
  return examState;
};

export const getUserDetails = (session, examSession, userType) => {
  const user = getLastActiveUserByType(examSession, userType);
  if (isEmpty(user)) { return undefined; }

  const typeLabel = userType.toLowerCase();
  session[typeLabel + 'Id'] = user.externalId;
  session[typeLabel + 'Name'] = user.fullName;
  session[typeLabel + 'Username'] = user.userName;
};

export const getStudentDetails = (session, examSession) => {
  if (isEmpty(examSession?.student)) { return undefined; }
  session['studentName'] = examSession.student.fullName || '-';
  session['studentId'] = examSession.student.externalId || '-';
};

export const getNoticeTimes = (examSession) => {
  if (isEmpty(examSession?.notices)) { return {}; }
  return Object.assign(...examSession.notices.map(notice => {
    return {
      [notice.type]: getFormattedDate(notice.creationDateTime, 'HH:mm'),
    };
  }));
};

export const getRoomScanStatus = (examSession) => {
  if (isEmpty(examSession)) { return undefined; }
  //later may need to group into Pending/Completed instead of actual value
  return examSession.onboardingType === EXAM_SESSION_ONBOARDING_TYPE.SKIP ? 'N/A' : examSession.roomScanStatus;
}

export const getAllocationPoolNames = (examSession) => {
  if (isEmpty(examSession?.poolAssignments)) {
    return undefined;
  }
  const poolNames = examSession.poolAssignments.map(pool => {
    return pool.allocationPool.name
  });
  return uniq(poolNames);
}

export const getFormattedDeletionDate = (examSession) => {
  if (!examSession) {
    return ' - ';
  }

  if (examSession.cannotExpire || isCannotExpireMisconductStatus(examSession.integrityReview)) {
    return 'Never expires';
  } else if (!isEmpty(examSession.overrideDeletionDate)) {
    return dateFnsFormat(parseISO(examSession.overrideDeletionDate), 'dd/MMM/yyyy');
  } else if (!isEmpty(examSession.context?.examDeletionDate)) {
    return dateFnsFormat(parseISO(examSession.context.examDeletionDate), 'dd/MMM/yyyy');
  }
  return ' - ';
}

const getExamDetails = (examSession) => {
  let session = {};
  if (isEmpty(examSession)) { return undefined; }

  session['id'] = examSession.id;
  session['examDate'] = getFormattedDate(examSession.examStartDateTime);
  session['examTime'] = getExamTime(examSession.examStartDateTime, examSession.examEndDateTime);
  session['examDuration'] = getExamDuration(examSession.examStartDateTime, examSession.examEndDateTime);
  session['examState'] = getExamState(examSession.examState);

  if (has(examSession, 'examType')) {
    session['type'] = typeLabels[examSession.examType];
  }
  if (has(examSession, 'context')) {
    session['contextId'] = examSession.context.id;
    session['unit'] = !isEmpty(examSession.context.name) ? examSession.context.name.split(' ')[0] : '-';
  }
  session['onboardingType'] = examSession.onboardingType;

  getUserDetails(session, examSession, USER_TYPES.ONBOARDER);
  getUserDetails(session, examSession, USER_TYPES.SUPERVISOR);
  getStudentDetails(session, examSession);

  return session;
};

export default getExamDetails;
