import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format as dateFnsFormat, isValid as dateFnsIsValid, parseISO } from 'date-fns';
import Section from '../Section';
import Notice from '../notification/Notice';
import { EXAM_SESSION_INTEGRITY } from '../../constants/examSessions';
import ExamSessionService from '../../services/ExamSessionService';
import isCannotExpireMisconductStatus from './helper/isCannotExpireMisconductStatus';

function ExamSessionMisconductForm(props) {
  const { examSession, updateSlot } = props;
  const [misconductStatus, setMisconductStatus] = useState(examSession.integrityReview);
  const [cannotExpire, setCannotExpire] = useState(examSession.cannotExpire);
  const [expiryDate, setExpiryDate] = useState(examSession.overrideDeletionDate ? parseISO(examSession.overrideDeletionDate) : undefined);
  const [requestError, setRequestError] = useState();
  const [requestPending, setRequestPending] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const handleSelectMisconduct = (event) => {
    const status = event.target.value;
    if (isCannotExpireMisconductStatus(status)) {
      setExpiryDate(null);
    }
    setMisconductStatus(status);
    setCannotExpire(cannotExpire || false);
  }

  const handleSelectCannotExpire = (event) => {
    if (event.target.value === 'true') {
      setCannotExpire(true);
      setExpiryDate(null);
    } else if (event.target.value === 'false') {
      setCannotExpire(false);
      setExpiryDate();
    }
  }

  const saveSession = async () => {
    if (!areFieldsValid()) {
      setRequestSuccessful(false);
      setRequestError('Sorry, please fill out all required fields before saving')
      return;
    }

    try {

      setRequestSuccessful(false);
      setRequestError();
      setRequestPending(true);

      // The date formatter forces the date selected to be formatted as midnight the same day local time
      const updateObject = {
        integrityReview: misconductStatus,
        overrideDeletionDate: expiryDate ? dateFnsFormat(expiryDate, "yyyy-MM-dd'T'00:00:00X") : null,
        cannotExpire: cannotExpire,
      }
      const updatedSlot = await ExamSessionService.updateExamSession(examSession.id, updateObject);

      setRequestSuccessful(true);
      setRequestPending(false);
      updateSlot(updatedSlot);
    } catch (error) {
      setRequestSuccessful(false);
      setRequestPending(false);
      setRequestError(error.message ? error.message : 'Sorry, we were unable to update the exam session');
    }
  }

  const areFieldsValid = () => {
    //Case 1: Slot cannot expire or the misconduct status has the cannot expire flag
    if (
      (misconductStatus && cannotExpire)
      || (misconductStatus && isCannotExpireMisconductStatus(misconductStatus) && cannotExpire !== undefined)
    ) {
      return true;
    }

    //Case 2: Slot can expire and the misconduct status does not have the cannot expire flag
    return misconductStatus && cannotExpire === false && expiryDate && dateFnsIsValid(expiryDate);
  }

  const formats = {
    normalDate: 'dd/MMM/yyyy',
    keyboardDate: 'dd/MMM/yyyy',
  };

  return (
    <Section>
      <FormControl component="fieldset" sx={{ display: 'block', margin: '0 auto' }}>
        <FormLabel component="legend" sx={{ color: 'primary.main', my: 1 }}>
          Exam session misconduct details
        </FormLabel>
        {requestSuccessful &&
          <Notice noticeType="success">Record saved</Notice>
        }
        {requestError &&
          <Notice noticeType="error">{requestError}</Notice>
        }
        <Typography sx={{ my: 1 }}>Record expiry date may be amended in the event of misconduct</Typography>
        <TextField
          select
          required
          size="medium"
          sx={{ my: 1 }}
          variant="outlined"
          label="Select status of misconduct case"
          value={misconductStatus ? misconductStatus : ''}
          fullWidth
          onChange={handleSelectMisconduct}
        >
          {Object.keys(EXAM_SESSION_INTEGRITY).map((key) => {
            return <MenuItem key={key} value={EXAM_SESSION_INTEGRITY[key].value}>{EXAM_SESSION_INTEGRITY[key].label}</MenuItem>
          })}
        </TextField>
        {misconductStatus &&
          <>
            <FormLabel
              id="exam-session-cannot-expire-radio-group"
              error={requestError && cannotExpire === undefined}
              required>
              {/* TODO: Make this configurable */}
              Is the student a law student?
            </FormLabel>
            <RadioGroup
              aria-labelledby="exam-session-cannot-expire-radio-group"
              name="exam-session-cannot-expire-radio-group"
              value={cannotExpire !== undefined ? cannotExpire : ''}
              onChange={handleSelectCannotExpire}
              required
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </>
        }
        {(cannotExpire === true || (isCannotExpireMisconductStatus(misconductStatus) && cannotExpire !== undefined)) &&
          <Typography>Record expiry date for a law student in a misconduct case is set by default to <strong>“Never expires”</strong>.
            Press <strong>“Save”</strong> to continue.</Typography>
        }
        {(cannotExpire === false && misconductStatus && !isCannotExpireMisconductStatus(misconductStatus)) &&
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
              <DatePicker
                disableMaskedInput
                format={formats.normalDate}
                label="Select expiry date"
                value={expiryDate ? expiryDate : null}
                onChange={setExpiryDate}
                slotProps={{
                  textField: {
                    id: 'expiryDate',
                    fullWidth: true,
                    required: true,
                    sx: { my: 1 },
                    variant: 'outlined',
                  }
                }}
              />
            </LocalizationProvider>
          </>
        }
        <Box display="flex" justifyContent="flex-end">
          {requestPending &&
            <CircularProgress disableShrink />
          }
          {!requestPending &&
            <Button
              color="primary"
              variant="contained"
              onClick={saveSession}
            >Save</Button>
          }
        </Box>
      </FormControl>
    </Section>);
}

ExamSessionMisconductForm.propTypes = {
  examSession: PropTypes.object.isRequired,
  updateSlot: PropTypes.func,
}

export default ExamSessionMisconductForm;
