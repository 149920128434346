import React, {  useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, has } from 'lodash';
import { monitoringContext } from '../context/MonitoringContext';
import {
  convertChatItemsToChatHistory,
  getUnreadChatMessagesCount,
  retrieveChatsFromCoreApi,
  sortAndDeDupeChats
} from '../../pages/helper/ChatMessages';
import GatewayService from '../../services/GatewayService';
import { ACTIONS } from '../../constants/monitorSessions';
import { ERROR_RETRIEVING_CHAT } from '../../constants/chat';

function MonitorChatContainer(props) {
  const { state, dispatch } = useContext(monitoringContext);
  const examSessions = useRef(state.examSessions);
  const controllerRef = useRef(new AbortController());
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const { slotId, renderContainer, userId, authorType } = props;

  const onSend = (gatewayService, fullName, messages = []) => {
    const audience = 'ALL';
    const thisExamSession = examSessions.current[slotId];
    const prevMessages = has(thisExamSession, 'chat') ? cloneDeep(thisExamSession.chat.messages) : [];

    messages.forEach(message => {
      if (message.text && message.text.length) {
        gatewayService.sendChat('MESSAGE', message.text, fullName, message.userId, audience);
        prevMessages.push(message);
      }
    });
    thisExamSession['chat']['messages'] = prevMessages;
    dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
  }

  const resetUnreadMessages = () => {
    const thisExamSession = examSessions.current[slotId];
    // reset if unread messages has ever been set. It should have an initial value from load
    if(has(thisExamSession, 'chat')) {
      thisExamSession['chat']['unreadMessages'] = 0;
      dispatch({type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession});
    }
  }

  useEffect(() => {
    examSessions.current = state.examSessions;
  }, [state.examSessions]);

  useEffect(() => {
    const getChatHistory = async () => {
      retrieveChatsFromCoreApi(slotId, controllerRef.current?.signal)
        .then(chatMessages => updateChatHistory(chatMessages))
        .catch(err => {
          console.error('[ChatContainer]: Failed to retrieve chat messages', err);
          const thisExamSession = examSessions.current[slotId];
          thisExamSession['chat']['errorRetrievingChats'] = ERROR_RETRIEVING_CHAT;
          thisExamSession['chat']['initialLoaded'] = true;
          dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession });
        });
    };

    const updateChatHistory = (chatMessages) => {
      const chatHistory = convertChatItemsToChatHistory(chatMessages, userId, authorType);
      const thisExamSession = examSessions.current[slotId];

      thisExamSession['chat']['messages'] = sortAndDeDupeChats([...chatHistory, ...thisExamSession?.chat?.messages || []]);
      thisExamSession['chat']['unreadMessages'] = getUnreadChatMessagesCount(thisExamSession?.chat?.messages, userId);
      thisExamSession['chat']['errorRetrievingChats'] = '';
      // try loading again, on next navigation to this session, if the request aborted
      thisExamSession['chat']['initialLoaded'] = !controllerRef.current?.signal?.aborted;
      dispatch({ type: ACTIONS.UPDATE_EXAM_SESSION, value: thisExamSession });
    };

    if (isInitialLoad && !examSessions.current[slotId]?.chat?.initialLoaded) {
      getChatHistory()
        .then(() => console.info('[MonitorChatContainer] loaded chat history for slot ', slotId))
        .catch((err) => console.error(err))
        .finally(() => setIsInitialLoad(false));
    }
  }, [slotId, dispatch, isInitialLoad, userId, authorType]);

  useEffect(() => {
    const controller = controllerRef?.current;
    return () => { controller.abort('[MonitorChatContainer]: Aborting due to component unmount') }
  }, []);

  if (!renderContainer) {
    // If the chat is flagged to be hidden, don't display it.
    return <></>;
  }

  const examSession = examSessions.current[slotId];

  const childrenWithProps = React.Children.map(props.children, child => {
    const childProps = {
      ...props,
      messages: examSession.chat.messages,
      onSend: onSend,
      unreadMessages: examSession.chat.unreadMessages,
      resetUnreadMessages: resetUnreadMessages,
      errorRetrievingChats: examSession.chat?.errorRetrievingChats,
    };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, childProps);
    }
    return child;
  });

  return (<>{childrenWithProps}</>);

}

MonitorChatContainer.propTypes = {
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  userId: PropTypes.string,
  authorType: PropTypes.string,
  renderContainer: PropTypes.bool,
  slotId: PropTypes.string,
};
MonitorChatContainer.defaultProps = {
  renderContainer: true,
};

export default MonitorChatContainer;
