import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChatBox from '../container/ChatBox';
import Notice from '../notification/Notice';
import GatewayService from '../../services/GatewayService';

function ChatPopup(props) {
  const [chatOpen, setChatOpen] = useState(false);

  const {
    messages,
    chatReady,
    gatewayService,
    onSend,
    unreadMessages,
    resetUnreadMessages,
    displayName,
    userId,
    authorType,
    errorRetrievingChats,
  } = props;

  return (
    <div>
      <Badge badgeContent={unreadMessages} color="error" overlap="rectangular" anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
        <Button
          color="primary"
          id="chat-popup-button"
          onClick={(_e) => setChatOpen(true)}
          startIcon={<QuestionAnswerIcon />}
          sx={{ textTransform: 'initial', textAlign: 'left', justifyContent: 'left' }}
          variant="contained"
        >
          Open chat
        </Button>
      </Badge>
      <Drawer
        anchor="right"
        open={chatOpen}
        onClose={(_e) => setChatOpen(false)}
        PaperProps={{ sx: { maxWidth: { xs: '95%', sm: '40%', md: '30%' }} }}
      >
        <Box
          position="relative"
          color="primary.contrastText"
          p={3}
          pr={4}
          display="flex"
          alignItems="center"
          sx={(theme) => { return {
            background: theme.palette.primary.dark,
            backgroundImage: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`}}}
        >
          <QuestionAnswerIcon />
          {authorType === 'STUDENT' &&
            <Typography variant="h2" sx={{ m: 0, pl: 1 }}>Chat to your supervisor</Typography>
          }
          <IconButton
            sx={{ color: 'primary.contrastText', position: 'absolute', top: 0, right: 0 }}
            aria-label="Close chat"
            onClick={(_e) => setChatOpen(false)}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        {chatReady ?
          <ChatBox
            onSend={onSend}
            messages={messages}
            displayName={displayName}
            userId={userId}
            authorType={authorType}
            gatewayService={gatewayService}
            resetUnreadMessages={resetUnreadMessages}
            errorRetrievingChats={errorRetrievingChats}
          />
        :
          <Box flexGrow="1" p={2}><Notice noticeType="warning">Sorry, there was an issue connecting to chat</Notice></Box>
        }
      </Drawer>
    </div>
  );
}

ChatPopup.propTypes = {
  chatReady: PropTypes.bool,
  gatewayService: PropTypes.instanceOf(GatewayService),
  displayName: PropTypes.string,
  authorType: PropTypes.string,
  userId: PropTypes.string,
  messages: PropTypes.array,
  onSend: PropTypes.func,
  unreadMessages: PropTypes.number,
  resetUnreadMessages: PropTypes.func,
  errorRetrievingChats: PropTypes.string,
};

export default ChatPopup;
